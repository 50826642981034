// import Swiper JS
import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const swiper = new Swiper('.js-swiper--standard', {
  speed: 400,
  loop: true,

  modules: [Navigation, Pagination],
  // Pagination fraction numbers
  pagination: {
    el: ".swiper-pagination-fraction",
    type: "fraction",
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});

export default Swiper;