window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */
  const nav = document.querySelector('.js-nav');
  const btnnav = document.querySelector('.js-toggle-icon');
  const body = document.querySelector('body');

  if (btnnav) {
    btnnav.addEventListener("click", () => {
      // Toggle menu to visible
      nav.classList.toggle('show');
      // Toggle burger icon
      btnnav.classList.toggle('icon-close');
      btnnav.classList.toggle('icon-menu');
      // Set Menuscroll
      body.classList.toggle('fix');
    });
  }
};

export default Navigation;